import Header from '@/components/header/header.vue'
import AOS from 'aos';
import 'aos/dist/aos.css';
import communLangage from '@/commun/commun.langage.js';


export default {
  name: "App",
  el: '#nav',
  components: {
    Header,
  },

  data() {
    return {

    }
  },

  computed: {
    langage() {
      return communLangage.langage;
    },

    baseUrl(){
      return process.env.BASE_URL
    },

    ongletCourant(){
      return this.$route.path.split('/')[1];
    },

    footerTexte(){
      return communLangage.footerTexte;
    }
  },


  methods: {


  },

  created() {
    AOS.init();

    this.estConnecte = this.$store.getters.estAuthentifie;
    this.unwatch = this.$store.watch(
      (state, getters) => getters.estAuthentifie,
      (newValue) => {
        this.estConnecte = newValue;
      }
    );
    
    communLangage.recupererLangage();
    if (this.langage == null) {
      var tmpLangage = window.navigator.language;

      if (tmpLangage.includes("en")) tmpLangage = "en";
      if (tmpLangage.includes("fr")) tmpLangage = "fr";
      var langageChoisi = tmpLangage ?? "fr"

      this.$store.dispatch('changerLangage', {
        langage: langageChoisi
      })
    }
  },

  watch:{
    ongletCourant: function(newValue){
      communLangage.definirPageACharger(newValue);
    }
  },

  beforeUnmount() {
    this.unwatch();
  },
}