import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    state: {
        identifiant: null,
        estConnecte: false,
        estDeveloppeur: false

    },
    getters: {
        estAuthentifie: state => state.estConnecte,
        identifiant: state => state.identifiant,
        estDeveloppeur: state => state.estDeveloppeur
    },

    actions: {
        connecterUtilisateur({commit},params) {
            commit('connecterUtilisateur', params);
            if(params.identifiant == "totobo") commit('passerModeDeveloppeur');
        },

        deconnecterUtilisateur({commit}) {
            commit('deconnecterUtilisateur');
            commit('passerModeUtilisateur');

        },

        changerLangage({commit},params){
            commit('changerLangage', params);

        }
    },

    mutations: {
        connecterUtilisateur(state, params) {
            // localStorage.setItem('identifiant', params.identifiant)
            state.identifiant = params.identifiant;
            state.estConnecte = true;
        },

        deconnecterUtilisateur(state) {
            // localStorage.clear();
            state.estConnecte = false;
            state.identifiant = null;


        },

        changerLangage(state,params){
            localStorage.setItem('langage', params.langage);

        },

        passerModeDeveloppeur(state){
            state.estDeveloppeur = true;
        },

        passerModeUtilisateur(state){
            state.estDeveloppeur = false;
        }
    },

    plugins: [createPersistedState()]
});


export default store
