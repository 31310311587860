import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/store.js'

const routes = [
  {
    path: '/connexion',
    name: 'connexion',
    component: () => import('../views/connexion/connexion.vue')
  },
  {
    path: '/',
    name: 'accueil',
    component: () => import('../views/accueil/accueil.index.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact/contact.vue')
  },
  {
    path: '/propos',
    name: 'propos',
    component: () => import('../views/propos/propos.vue')
  },
  {
    path: '/competences',
    name: 'competences',
    component: () => import('../views/CV/competences.vue')
  },
  {
    path: '/projets',
    name: 'projets',
    component: () => import('../views/projet/projet.general.vue'),
    children: [
      {
        path: '',
        name: 'projets.index',
        component: () => import('../views/projet/projet.index.vue')
      },
      {
        path: ':type',
        name: 'projets.type',
        component: () => import('../views/projet/projet.type.vue')
      },
      {
        path: ':type/:nomProjet',
        name: 'projets.informations',
        component: () => import('../views/projet/projet.informations.vue'),
      }
    ]

  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('../views/CV/documents.vue')
  },
  {
    path: '/flexmaint',
    name: ' flexmaint',
    component: () => import('../views/flexmaint/flexmaint.vue')
  },
  {
    path: '/compteurPause',
    name: ' compteurPause',
    component: () => import('../views/compteurPause/compteurPause.vue')
  },
  {
    path: '/mentionsLegales',
    name: 'Mentions légales',
    component: () => import('../views/mentionsLegales/mentionsLegales.vue')
  },
  { 
    path: "/:pathMatch(.*)*", 
  name: 'Page non trouvée',
  component: () => import('../views/pageNonTrouvee/pageNonTrouvee.vue') 
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.connecte)) {
    if (store.getters.estAuthentifie) {
      next();
      return;
    }
    next("/connexion");
  } else {
    next();
  }
});

export default router
