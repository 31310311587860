import communLangage from "@/commun/commun.langage.js";

export default {
     name: "Header",

     components: {
     },

     data() {
          return {
               estConnecte: false,
               menu: false,
               entreeMenu: [],
               nav:{}
          }
     },

     computed: {
          langage() {
               return communLangage.langage;
          },

          headerTexte(){
               return communLangage.headerTexte;
          },

          route(){
               return this.$route.path.split('/')[1] == "" ? "accueil" : this.$route.path.split('/')[1];
          }
     },

     methods: {
          deconnecter() {
               this.$store.dispatch("deconnecterUtilisateur");
               this.$router.push('/connexion');
          },

          afficherProfil() {

          },

          changerLangage(langage) {
               this.$store.dispatch('changerLangage', {
                    langage: langage
               })
               communLangage.recupererLangage();

          },

          

     },

    

     created() {
          this.estConnecte = this.$store.getters.estAuthentifie;
          communLangage.recupererLangage();
     },

}