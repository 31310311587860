import { nextTick, reactive, watch } from "vue";
import axios from "axios";

class langage {
  constructor() {
    this.langage = "fr";
    this.pageACharger = "";

    this.infosGenerales = null;
    this.formation = null;
    this.parcoursPro = null;

    this.documentTexte = null;

    this.projetTexte = null;

    this.competences = null;

    this.headerTexte = null;
    this.footerTexte = null;

    this.contactTexte = null;

    this.autreTexte = null;
  }

  recupererLangage() {
    this.langage = localStorage.getItem("langage");
  }

  definirPageACharger(pageACharger) {
    this.pageACharger = pageACharger;
  }

  recupererTexteAccueil() {
    axios.get(process.env.BASE_URL + "assets/textes/accueil/informationsGenerales.json").then((result) => {
      this.infosGenerales = {};
      this.infosGenerales = this.langage == "fr" ? result.data.infos.fr : result.data.infos.en;
      this.infosGenerales.titres = this.langage == "fr" ? result.data.titres.fr : result.data.titres.en;
      this.infosGenerales.infosImg = this.langage == "fr" ? result.data.infosImg.fr : result.data.infosImg.en;
    });
    axios.get(process.env.BASE_URL + "assets/textes/accueil/formation.json").then((result) => {
      this.formation = {};
      this.formation.ecoles = [];
      this.formation.titres = this.langage == "fr" ? result.data.titres.fr : result.data.titres.en;
      result.data.ecoles.forEach((element) => {
        var tmp = this.langage == "fr" ? element.fr : element.en;
        tmp.nom = element.nom;
        tmp.code = element.code;
        tmp.img = element.img;
        this.formation.ecoles.push(tmp);
      });
      axios.get(process.env.BASE_URL + "assets/textes/accueil/parcoursProfessionnel.json").then((result) => {
        this.parcoursPro = {};
        this.parcoursPro.entreprises = [];
        this.parcoursPro.titres = this.langage == "fr" ? result.data.titres.fr : result.data.titres.en;
        result.data.entreprises.forEach((element) => {
          var tmp = this.langage == "fr" ? element.fr : element.en;
          tmp.nom = element.nom;
          tmp.code = element.code;
          tmp.afficherPlus = false;
          tmp.plusInformation = element.plusInformation;
          tmp.img = element.img;
          tmp.imgLogiciel = element.imgLogiciel;

          this.parcoursPro.entreprises.push(tmp);
        });
      });
      axios.get(process.env.BASE_URL + "assets/textes/accueil/informationsGenerales.json").then((result) => {
        this.infosGenerales.identite = [];
        this.infosGenerales.identite = this.langage == "fr" ? result.data.titres.fr : result.data.titres.en;
      });
    });
  }

  recupererTexteCompetence() {
    axios.get(process.env.BASE_URL + "assets/textes/competences/competences.json").then((result) => {
      this.competences = {};
      this.competences = this.langage == "fr" ? result.data.fr : result.data.en;
    });
  }

  recupererTexteDocument() {
    axios.get(process.env.BASE_URL + "assets/textes/documents/documents.json").then((result) => {
      this.documentTexte = {};
      this.documentTexte = this.langage == "fr" ? result.data.fr : result.data.en;
    });
  }

  recupererTexteHeader() {
    axios.get(process.env.BASE_URL + "assets/textes/header/menu.json").then((result) => {
      axios.get(process.env.BASE_URL + "assets/textes/header/nav.json").then((result2) => {
        this.headerTexte = {};
        this.headerTexte.entreeMenu = this.langage == "fr" ? result.data.fr : result.data.en;
        this.headerTexte.nav = this.langage == "fr" ? result2.data.fr : result2.data.en;
      });
    });
  }

  recupererTexteFooter() {
    axios.get(process.env.BASE_URL + "assets/textes/footer/footer.json").then((result) => {
      this.footerTexte = {};
      this.footerTexte = this.langage == "fr" ? result.data.fr : result.data.en;
    });
  }

  recupererTexteProjet() {
    axios.get(process.env.BASE_URL + "assets/textes/projets/projets.json").then((result) => {
      this.projetTexte = {};
      this.projetTexte.projets = [];
      result.data.projets.forEach((element) => {
        var tmp = this.langage == "fr" ? element.fr : element.en;
        tmp.code = element.code;
        tmp.img = element.img == "" ? "point-dinterrogation.png" : element.img;
        tmp.type = element.type;
        tmp.id = element.id;
        this.projetTexte.projets.push(tmp);
      });
      this.projetTexte.titres = this.langage == "fr" ? result.data.titres.fr : result.data.titres.en;
    });
  }
  recupererTexteContact() {
    axios.get(process.env.BASE_URL + "assets/textes/contact/contact.json").then((result) => {
      this.contactTexte = {};
      this.contactTexte = this.langage == "fr" ? result.data.fr : result.data.en;
    });
  }
  recupererTexteAutre() {
    axios.get(process.env.BASE_URL + "assets/textes/autre/autre.json").then((result) => {
      this.autreTexte = {};
      this.autreTexte = this.langage == "fr" ? result.data.fr : result.data.en;
    });
  }

  chargerTexte() {
    switch (this.pageACharger) {
      case "accueil":
        this.recupererTextesAccueil();
        break;
      case "competences":
        this.recupererTexteCompetence();
        break;
      case "documents":
        this.recupererTexteDocument();
        break;
      case "projets":
        this.recupererTexteProjet();
        break;
      case "contact":
        this.recupererTexteContact();
        break;
      default:
        this.recupererTexteAccueil();
    }
  }
}

var vm = reactive(new langage());

nextTick(() => {
  watch(
    () => vm.pageACharger,
    () => {
      vm.chargerTexte();
    }
  );

  watch(
    () => vm.langage,
    () => {
      vm.chargerTexte();
      vm.recupererTexteHeader();
      vm.recupererTexteFooter();
      vm.recupererTexteAutre();
    }
  );

  vm.recupererLangage();

  vm.recupererTexteHeader();
  vm.recupererTexteFooter();
  vm.recupererTexteAutre();
  vm.chargerTexte();
});

export default vm;
