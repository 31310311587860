import { reactive } from "vue";

class general {
    constructor() {
        this.vscroll = true;
    }
}

var vm = reactive(new general());

export default vm;


