<template>
  <div v-if="headerTexte">
    <div v-scroll-nav-espace-client style="position: relative">
      <div class="nav-espace-client" style="height: var(--nav-espace-client-height)">
        <span style="flex-basis: 45%" class="mr-3 text-center">{{ headerTexte.nav.bienvenue }} {{ $store.getters.identifiant }} !</span>

        <div style="flex-basis: 45%" class="mr-3 m-1 d-flex align-items-center justify-content-end">
          <div v-if="estConnecte" class="d-flex align-items-center">
            <!-- <div class="balise-a text-white">
            <span class="fa fa-id-card mr-1" @click="afficherProfil()"> </span
            >{{nav.profil}}
          </div> -->
            <button v-if="estConnecte" class="btn btn-info btn-sm ml-3 d-flex align-items-center" @click="deconnecter()">
              <span class="fa fa-sign-out-alt"></span><span class="mode-ordi ml-1">{{ headerTexte.nav.deconnexion }}</span>
            </button>
          </div>
          <button v-if="!estConnecte" class="btn btn-info btn-sm ml-3" @click="$router.push('/connexion')"><span class="fa fa-user mr-1"></span>{{ headerTexte.nav.espaceClient }}</button>
        </div>
      </div>
      <div class="dropdown" style="position: absolute; top: 0%; right: 50%; left: 50%">
        <button class="btn btn-sm btn-secondary dropdown-toggle" style="width: 75px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img :src="require(`@/assets/logo/drapeau-${langage == 'fr' ? 'fr' : 'en'}.png`)" :alt="`Logo ${langage == 'fr' ? 'FR' : 'EN'}`" style="width: 50%" />

          <span class="ml-1">{{ langage == "fr" ? "FR" : "EN" }}</span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <div class="dropdown-item" @click="changerLangage('fr')">
            <img src="@/assets/logo/drapeau-fr.png" alt="Logo FR" style="width: 30%" />
            FR
          </div>
          <div class="dropdown-item" @click="changerLangage('en')">
            <img src="@/assets/logo/drapeau-en.png" alt="Logo EN" style="width: 30%" />
            EN
          </div>
        </div>
      </div>
    </div>
    <div id="nav" class="box w-100" v-scroll>
      <div class="d-flex flex-row justify-content-between align-items-center flex-tel-justify-start" style="height: var(--nav-height)">
        <span class="fa fa-2x fa-arrow-left" style="cursor: pointer" @click="$router.go(-1)" v-if="$route.path.split('/')[2] != null"></span>
        <img src="@/assets/logo.png" style="max-width: 50px; max-height: 35px" @click="$router.push('/')" class="balise-a" v-else />
        <div id="titre-header" class="d-flex align-items-center ml-2"></div>
        <div
          id="icone-menu-tel"
          @click="
            () => {
              menu = !menu;
            }
          "
          :class="menu ? 'clicked' : ''"
        >
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
        <nav id="menu-tel" :class="menu ? 'visible' : 'invisible'">
          <ul
            @click="
              () => {
                menu = !menu;
              }
            "
          >
            <li v-for="(entree, index) in headerTexte.entreeMenu.filter((x) => x.actif)" :key="index" class="d-flex justify-content-center">
              <router-link class="w-75" :to="`/${entree.link}`" :class="entree.code == route ? 'text-info' : ''">{{ entree.nom }} </router-link>
            </li>

            <!-- <li><router-link to="/solutions">Nos solutions +</router-link></li> -->
            <!-- <li><router-link to="/realisations">Examples de réalisations +</router-link></li> -->
            <!-- <li><router-link to="/idee">Proposer un projet / une idées</router-link></li> -->

            <!-- <li><router-link to="/propos">Réalisation de mon site</router-link></li> -->
            <!-- <li><router-link to="/propos">La nuit de l'info</router-link></li> -->
          </ul>
        </nav>
        <div id="menu" style="font-size: 120%">
          <!-- <div class="solutions" v-if="$store.getters.estDeveloppeur">
            <router-link class="mr-5" to="/solutions">
              Nos solutions +
            </router-link>
            <div class="solutions-dropdown couleur-header">
              <div class="" style="font-size: 105%">
                <div @click="$router.push('/connexion')" class="p-2 bg-item">
                  Action
                </div>
                <div @click="$router.push('/connexion')" class="p-2 bg-item">
                  Another action
                </div>
                <div @click="$router.push('/connexion')" class="p-2 bg-item">
                  Something else here
                </div>
              </div>
            </div>
          </div>
          <div class="realisations" v-if="$store.getters.estDeveloppeur">
            <router-link class="mr-5" to="/realisations"
              >Examples de réalisations +
            </router-link>
            <div class="realisations-dropdown couleur-header">
              <div class="" style="font-size: 105%">
                <div @click="$router.push('/connexion')" class="p-2 bg-item">
                  Action
                </div>
                <div @click="$router.push('/connexion')" class="p-2 bg-item">
                  Another action
                </div>
                <div @click="$router.push('/connexion')" class="p-2 bg-item">
                  Something else here
                </div>
              </div>
            </div>
          </div>
          <router-link class="mr-5" to="/idee"
             v-if="$store.getters.estDeveloppeur">Proposer un projet / une idées</router-link
          > -->
          <span v-for="(entree, index) in headerTexte.entreeMenu.filter((x) => x.actif)" :key="index" data-aos="zoom-in-right" data-aos-duration="1500">
            <router-link class="mr-5" :to="`/${entree.link}`" :class="entree.code == route ? 'text-info' : ''">{{ entree.nom }} </router-link>
          </span>

          <!-- <router-link class="mr-5" to="/propos" v-if="$store.getters.estDeveloppeur">Réalisation de mon site</router-link> -->
          <!-- <router-link class="mr-5" to="/propos" v-if="$store.getters.estDeveloppeur">La nuit de l'info</router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./header.js"></script>

<style scoped>
.realisations {
  /* position: relative; */
}

.realisations-dropdown {
  position: absolute;
  display: none;
}

.realisations:hover .realisations-dropdown {
  display: block;
}

.solutions {
  /* position: relative; */
}

.solutions-dropdown {
  position: absolute;
  display: none;
}

.solutions:hover .solutions-dropdown {
  display: block;
}

.bg-item:hover {
  background-color: aqua;
}

#icone-menu-tel {
  position: absolute;
  top: 2.5em;
  right: 3.5em;
  display: inline-block;
  cursor: pointer;
}
#icone-menu-tel .bar1,
#icone-menu-tel .bar2,
#icone-menu-tel .bar3 {
  width: 32px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
  transition: 0.4s;
}
#icone-menu-tel:hover {
  opacity: 0.75;
  transition: 0.5s;
}
#icone-menu-tel.clicked .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}
#icone-menu-tel.clicked .bar2 {
  opacity: 0;
}
#icone-menu-tel.clicked .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

/* Main menu (slide) */
#menu-tel {
  position: fixed;
  z-index: 1004;
  left: -25%;
  top: auto;
  background: #f5f5f5;
  padding: 2% 10% 2% 2%;
  margin-top: 420px;
  width: 25%;
  min-height: 100%;
  box-shadow: 1px 0 1px #d3cfcf;
}
#menu-tel ul li {
  list-style: none;
}
#menu-tel ul li a {
  display: block;
  z-index: 1005;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  background: #2c3e50;
  transition: background 0.5s;
  padding: 1em;
  text-align: center;
  margin-bottom: 0.5em;
  box-shadow: 1px 1px 0 #666;
}
#menu-tel ul li a:hover {
  background: #f1c40f;
  transition: background 0.5s;
}

#menu-tel.visible {
  animation: 0.5s slideRight ease-in forwards;
  transition-delay: 0;
}
#menu-tel.invisible {
  animation: 1s slideLeft ease-out forwards;
  transition-delay: 0;
}

/* Animations pour le menu slide */
@keyframes slideRight {
  from {
    left: -25%;
  }
  to {
    left: 0%;
  }
}
@-webkit-keyframes slideRight {
  from {
    left: -25%;
  }
  to {
    left: 0%;
  }
}
@keyframes slideLeft {
  from {
    left: 0%;
  }
  to {
    left: -25%;
  }
}
@-webkit-keyframes slideLeft {
  from {
    left: 0%;
  }
  to {
    left: -25%;
  }
}

@media (max-width: 600px) {
  #icone-menu-tel {
    top: 1em;
    right: 2em;
    z-index: 9999;
  }
  #menu-tel {
    left: -100%;
    width: 100%;
  }
  #menu {
    display: none;
  }

  /* Animations pour le menu slide */
  @keyframes slideRight {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  @-webkit-keyframes slideRight {
    from {
      left: -100%;
    }
    to {
      left: 0%;
    }
  }
  @keyframes slideLeft {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
  @-webkit-keyframes slideLeft {
    from {
      left: 0%;
    }
    to {
      left: -100%;
    }
  }
}

@media (min-width: 600px) {
  #icone-menu-tel {
    display: none;
  }
  #menu-tel {
    display: none;
  }
  #menu {
    display: flex;
  }
}

.dropdown {
  z-index: 1006;
}
</style>
