<template>
  <div class="background-color-body w-100" style="height: var(--height)" id="app">
    <div style="height: var(--header-height)" class="w-100">
      <Header class="h-100" />
    </div>
    <div style="height: var(--body-height)" class="d-flex flex-column justify-content-between div-body">
      <router-view class="w-100 background-color-body router-view" style="flex-basis: 100%" />
      <div class="p-2 text-center" style="background: linear-gradient(#e0e0e0, #b7b3b3)" v-if="footerTexte">
        <span class="fa fa-copyright"></span> {{footerTexte.creationDe}}
        <span @click="$router.push('/compteurPause')"> Thomas</span>
        <span @click="$router.push('/flexmaint')"> BEAUD</span> |
        <!-- <a :href="baseUrl + 'assets/fichiers/MentionsLegalesThomasbeaud.pdf'" target="_blank">Mentions légales</a> -->
        <span @click="$router.push('/mentionsLegales')" class="cursor-pointer">{{footerTexte.mentionsLegales}}</span>
      </div>
    </div>
  </div>
</template>

<script src="./App.js"></script>

<style src="@/assets/style.css"></style>

<style>
:root {
  --height: 100vh;
  --header-height: 120px;
  --nav-espace-client-height: 40px;
  --nav-height: 80px;
  --body-height: calc(var(--height) - var(--header-height) - 0px);
  --titre-height: 120px;
  --infos-height: calc(var(--body-height) - var(--titre-height) - 120px);
}
</style>
